var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "领用记录信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-submit": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.dataForm,
                    callback: function ($$v) {
                      _vm.dataForm = $$v
                    },
                    expression: "dataForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "issueCode" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "calc(100% - 120px)" },
                        attrs: {
                          maxlength: "100",
                          disabled: _vm.type == "view",
                        },
                        model: {
                          value: _vm.dataForm.issueCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "issueCode", $$v)
                          },
                          expression: "dataForm.issueCode",
                        },
                      }),
                      _c("el-switch", {
                        attrs: {
                          "active-text": "自动生成",
                          disabled: _vm.type == "view",
                        },
                        on: { change: _vm.handleSwidth },
                        model: {
                          value: _vm.generate,
                          callback: function ($$v) {
                            _vm.generate = $$v
                          },
                          expression: "generate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "userName" },
                    [
                      _c("el-input", {
                        ref: "userName",
                        attrs: { disabled: _vm.type == "view" },
                        on: { focus: _vm.changeUser },
                        model: {
                          value: _vm.dataForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "userName", $$v)
                          },
                          expression: "dataForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "add"
        ? [
            _c(
              "el-container",
              [
                _c(
                  "el-main",
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "物资清单",
                        "head-btn-options": _vm.inventoryBtnOptions,
                      },
                      on: {
                        "inventory-add": _vm.handleDetailAdd,
                        "inventory-remove": _vm.rowRemove,
                      },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "grid-row-btn": _vm.gridRowBtn,
                        "table-options": _vm.tableOptions,
                        "table-data": _vm.tableData,
                        "data-total": _vm.page.total,
                        page: _vm.page,
                        "table-loading": _vm.tableLoading,
                        editType: _vm.inline,
                      },
                      on: {
                        "page-current-change": _vm.handleCurrentChange,
                        "page-size-change": _vm.handleSizeChange,
                        "page-refresh-change": _vm.onLoad,
                        "row-edit": _vm.rowEdit,
                        "row-remove": _vm.rowRemove,
                        "gird-handle-select-click": _vm.selectionChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.dialogVisible,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物资清单",
            visible: _vm.detailDialog,
            width: "60%",
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialog = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              ref: "detailLayout",
              staticStyle: { padding: "10px" },
              attrs: { option: _vm.detailOptions },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.detailForm,
                callback: function ($$v) {
                  _vm.detailForm = $$v
                },
                expression: "detailForm",
              },
            },
            [
              _c(
                "template",
                { slot: "quantity" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.detailForm.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "quantity", $$v)
                      },
                      expression: "detailForm.quantity",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }